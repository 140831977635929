import * as bootstrap from 'bootstrap';
import './modules/forms.js'

(function() {
  "use strict";

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all)
    if (selectEl) {
      if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener))
      } else {
        selectEl.addEventListener(type, listener)
      }
    }
  }

  /**
   * Easy on scroll event listener
   */
  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }

  /**
   * Navbar links active state on scroll
   */
  let navbarlinks = select('#navbar .scrollto', true)
  const navbarlinksActive = () => {
    let position = window.scrollY + 200
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return
      let section = select(navbarlink.hash)
      if (!section) return
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active')
      } else {
        navbarlink.classList.remove('active')
      }
    })
  }
  window.addEventListener('load', navbarlinksActive)
  onscroll(document, navbarlinksActive)

  /**
   * Scrolls to an element with header offset
   */
  const scrollto = (el) => {
    let header = select('#header')
    let offset = header.offsetHeight

    if (!header.classList.contains('header-scrolled')) {
      offset -= 20
    }

    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }

  /**
   * Toggle .header-scrolled class to #header when page is scrolled
   */
  let selectHeader = select('#header')
  if (selectHeader) {
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add('header-scrolled')
      } else {
        selectHeader.classList.remove('header-scrolled')
      }
    }
    window.addEventListener('load', headerScrolled)
    onscroll(document, headerScrolled)
  }

  /**
   * Back to top button
   */
  let backtotop = select('.back-to-top')
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add('active')
      } else {
        backtotop.classList.remove('active')
      }
    }
    window.addEventListener('load', toggleBacktotop)
    onscroll(document, toggleBacktotop)
  }

  /**
   * Mobile nav toggle
   */
  on('click', '.mobile-nav-toggle', function(e) {
    select('#navbar').classList.toggle('navbar-mobile')
    this.classList.toggle('bi-list')
    this.classList.toggle('bi-x')
  })

  /**
   * Mobile nav dropdowns activate
   */
  on('click', '.navbar .dropdown > a', function(e) {
    if (select('#navbar').classList.contains('navbar-mobile')) {
      e.preventDefault()
      this.nextElementSibling.classList.toggle('dropdown-active')
    }
  }, true)

  /**
   * Scrool with ofset on links with a class name .scrollto
   */
  on('click', '.scrollto', function(e) {
    if (select(this.hash)) {
      e.preventDefault()

      let navbar = select('#navbar')
      if (navbar.classList.contains('navbar-mobile')) {
        navbar.classList.remove('navbar-mobile')
        let navbarToggle = select('.mobile-nav-toggle')
        navbarToggle.classList.toggle('bi-list')
        navbarToggle.classList.toggle('bi-x')
      }
      scrollto(this.hash)
    }
  }, true)

  /**
   * Scroll with ofset on page load with hash links in the url
   */
  window.addEventListener('load', () => {
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash)
      }
    }
  });

  /**
   * Testimonials slider
   */
  /*
  new Swiper('.testimonials-slider', {
    speed: 600,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },

      1200: {
        slidesPerView: 2,
        spaceBetween: 20
      }
    }
  });
  */

  /**
   * Porfolio isotope and filter
   */
  window.addEventListener('load', () => {
    let projectsContainer = select('.projects-container');
    if (projectsContainer) {
      let projectsIsotope = new Isotope(projectsContainer, {
        itemSelector: '.project-item',
        layoutMode: 'fitRows'
      });

      let projectsFilters = select('#projects-filters li', true);

      on('click', '#projects-filters li', function(e) {
        e.preventDefault();
        projectsFilters.forEach(function(el) {
          el.classList.remove('filter-active');
        });
        this.classList.add('filter-active');

        projectsIsotope.arrange({
          filter: this.getAttribute('data-filter')
        });
        projectsIsotope.on('arrangeComplete', function() {
          AOS.refresh()
        });
      }, true);
    }

  });

  /**
   * Initiate projects lightbox
   */
  const projectLightbox = GLightbox({
    selector: '.project-lightbox'
  });

  /**
   * Project details slider
   */
  /*
  new Swiper('.project-details-slider', {
    speed: 400,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });
  */

  /**
   * Animation on scroll
   */
  window.addEventListener('load', () => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    })
  });

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, { trigger: 'focus' });
  });

  /**
   * Contact Estimate Form
   */
  const contactForm = document.getElementById('contact-form');

  if (contactForm) {
    contactForm.addEventListener('submit', function (e) {
      const contactAlertBox = document.getElementById('contact-alert-box');
      const contactAlert = document.getElementById('contact-alert');
      const contactSubmitBtn = document.getElementById('contact-submit-btn');
      const contactSubmitSpinner = document.getElementById('contact-spinner');
      const contactSubmitText = document.getElementById('contact-submit-text');

      const service = document.getElementById('service').value;
      const name = document.getElementById('name').value;
      const company = document.getElementById('company').value;
      const email = document.getElementById('email').value;
      const phone = document.getElementById('phone').value;
      const pickupDate = document.getElementById('pickup-date').value;
      const flexibilityCheckbox = document.getElementById('flexibility');
      const city = document.getElementById('city').value;
      const state = document.getElementById('state').value;
      const country = document.getElementById('country').value;
      const comments = document.getElementById('comments').value;

      const prevText = contactSubmitText.innerText;

      const flexibility = flexibilityCheckbox.checked ? 'yes' : 'no';

      contactSubmitBtn.disabled = true;
      contactSubmitText.innerText = 'Submitting ...'
      contactSubmitSpinner.classList.remove('hidden');

      $.ajax({
        type: 'post',
        url: API.contact,
        data: JSON.stringify({
          'service': service,
          'name': name,
          'company': company,
          'email': email,
          'phone': phone,
          'pickupDate': pickupDate,
          'flexibility': flexibility,
          'city': city,
          'state': state,
          'country': country,
          'comments': comments,
        }),
        contentType: 'application/json',
      }).done(function (data) {
        if (data.success) {
          contactAlert.classList.remove('alert-danger');
          contactAlert.classList.add('alert-success');
          contactAlert.classList.add('py-5');
          contactAlert.innerHTML
            = 'Thank you, your information has been sent.' +
            '<br>We will get in touch with you as soon as possible!'
          const contactFormWrapper = contactForm.parentNode;
          contactFormWrapper.classList.add('hidden');
        } else {
          contactSubmitBtn.disabled = false;
          contactAlert.classList.add('alert-danger');
          contactAlert.innerHTML = data.error;
        }
        contactAlertBox.classList.remove('hidden');
        contactSubmitText.innerText = prevText;
        contactSubmitSpinner.classList.add('hidden');

        // Scroll to the top where the error should be.
        $([document.documentElement, document.body]).animate({
          scrollTop: ($('#contact-alert-box').offset().top - 100),
        }, 500);
      }).fail(function (data) {
        contactSubmitSpinner.classList.add('hidden');
        contactAlert.classList.add('alert-danger');
        contactAlert.innerHTML
          = 'Oops, something went wrong!<br> Please refresh and try again.';
        contactAlertBox.classList.remove('hidden');
        contactSubmitText.innerText = prevText;
        contactSubmitSpinner.classList.add('hidden');

        // Scroll to the top where the error should be.
        $([document.documentElement, document.body]).animate({
          scrollTop: ($('#contact-alert-box').offset().top - 100),
        }, 500);
      });

      e.preventDefault();
    });
  }
})()
