(function () {
  "use strict";

  let forms = document.querySelectorAll('.contact-form');

  forms.forEach(function(e) {
    e.addEventListener('submit', function(event) {
      event.preventDefault();

      let thisForm = this;

      let url = thisForm.getAttribute('action');

      if (!url) {
        displayError(thisForm, 'The form action property is not set!')
        return;
      }

      thisForm.querySelector('.loading').classList.add('d-block');
      thisForm.querySelector('.error-message').classList.remove('d-block');
      thisForm.querySelector('.sent-message').classList.remove('d-block');

      let formData = new FormData(thisForm);
      let recaptcha = thisForm.querySelector('.g-recaptcha');

      if (recaptcha) {
        let siteKey = recaptcha.getAttribute('data-sitekey');

        if (typeof grecaptcha !== "undefined") {
          grecaptcha.ready(function() {
            try {
              // Invoke the recaptcha check, and then submit the form. This will
              // populate the 'g-recaptcha-response' field in the form.
              grecaptcha.execute(siteKey)
              .then(function (token) {
                formData.set('g-recaptcha-response', token);
                form_submit(thisForm, url, formData);
              })
            } catch(error) {
              displayError(thisForm, error)
            }
          });
        } else {
          displayError(thisForm, 'The recaptcha javascript API has not loaded!')
        }
      } else {
        form_submit(thisForm, url, formData);
      }
    });
  });

  function form_submit(formObj, url, formData) {
    fetch(url, {
      method: 'POST',
      body: formData,
      headers: {'X-Requested-With': 'XMLHttpRequest'}
    })
    .then(response => {
      if (response.ok) {
        return response.json() // Read response and parse as JSON
      } else {
        throw new Error(`${response.status} ${response.statusText} ${response.url}`);
      }
    })
    .then(data => {
      formObj.querySelector('.loading').classList.remove('d-block');
      if (data.success) {
        formObj.querySelector('.sent-message').classList.add('d-block');
        formObj.reset();
      } else {
        throw new Error(data.error ? data.error :
                        'Form submission failed yet no error message was returned: ' + url);
      }
    })
    .catch((error) => {
      displayError(formObj, error);
    });
  }

  function displayError(formObj, error) {
    if (error instanceof Error) {
      error.name = ''; // Remove the 'Error: ' prefix
    }
    formObj.querySelector('.loading').classList.remove('d-block');
    formObj.querySelector('.error-message').innerHTML = error;
    formObj.querySelector('.error-message').classList.add('d-block');
  }

})();
